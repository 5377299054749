/**
 * - InsideUsAll,
 * - PinkRoom,
 * - Gravity,
 * - BeatSalad,
 * - 
 */

const mapOfMeData = [
	{
		type: 'unity',
		stack: '(Unity3D, Blender)',
		project: 'InsideUsAll',
		description:
			"I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic. I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic.",
		imgs: [ 'https://i.imgur.com/krpiK7E.png' ],
		github: ''
	},
	{
		type: 'unity',
		stack: '(Unity3D, Blender)',
		project: 'Gravity',
		description:
			"I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic. ",
		imgs: [ 'https://i.imgur.com/krpiK7E.png' ],
		github: ''
	},
	{
		type: 'unity',
		stack: '(Unity3D, Blender)',
		project: 'BeatSalad',
		description:
			"I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic. ",
		imgs: [ 'https://i.imgur.com/krpiK7E.png' ],
		github: ''
	},
	{
		type: 'unity',
		stack: '(Unity3D, Blender)',
		project: 'BeatSalad',
		description:
			"I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic. ",
		imgs: [ 'https://i.imgur.com/krpiK7E.png' ],
		github: ''
	},
	{
		type: 'unity',
		stack: '(Unity3D, Blender)',
		project: 'BeatSalad',
		description:
			"I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic. ",
		imgs: [ 'https://i.imgur.com/krpiK7E.png' ],
		github: ''
	},
	{
		type: 'unity',
		stack: '(Unity3D, Blender)',
		project: 'BeatSalad',
		description:
			"I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic. ",
		imgs: [ 'https://i.imgur.com/krpiK7E.png' ],
		github: ''
	},
	{
		type: 'unity',
		stack: '(Unity3D, Blender)',
		project: 'BeatSalad',
		description:
			"I'm baby woke shabby chic photo booth banh mi, pour-over etsy deep v organic activated charcoal kitsch portland. Kitsch craft beer disrupt, pickled pour-over copper mug tumeric roof party portland iPhone quinoa lyft aesthetic. ",
		imgs: [ 'https://i.imgur.com/krpiK7E.png' ],
		github: ''
	}
];

export default mapOfMeData;
