export const data = [
	"Mountains can be moved. An endeavour in which technology has no small part, no small responsibility. Be it in medicine, the environment, infrastructure, education, academic disciplines, journalism, mental health, communication, economic (or any) policy, entertainment, architecture or art. Technology is, or should be, a veritable well-spring of miracles.",
];

export const subData =
	"It's the love of building what leaves lasting impressions; building products of beauty, and meaning.";

export const coreSkills = [
	"JavaScript (ES6+)",
	"ReactJS",
	"React-Native",
	"NodeJS",
	"Unity3D",
    "C#",
	"Blender",
	"Adobe CC",
];
